import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material';

import i18n from '../../../i18n';
import en from './i18n/LangPicker_en.json';
import de from './i18n/LangPicker_de.json';

import { vars } from '../../../Theme';

i18n.addResourceBundle('en', 'langPicker', en);
i18n.addResourceBundle('de', 'langPicker', de);

/** Styled for the UI specifications. */
const LangSelect = styled(Select)(() => ({
    '& .MuiOutlinedInput-input': {
        padding: 0,
        
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 0
    },
    '& .MuiSelect-icon': {
      color: vars.concrete
  },
}));

/**
 * Dropdown that allows the user to set the application language.
 */
const LangPicker = () => {
    // Configure translation.
    const { t } = useTranslation('langPicker');

    // State for getting and setting the application language.
    const [lang, setLang] = useState(i18n.language);

    // Generate a list of the avalable languages as <MenuItem> Components.
    const langs = (i18n.languages as any)
        .sort()
        .map((x: string, i: number) => <MenuItem value={x} key={`lang_${i}`}>{t(x)}</MenuItem>);

    /**
     * Updates the Application language when the user selects on the of dropdown items.
     * @param event - Change event emitted by the Select Component.
     */
    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const newLang: string = event.target.value as string;

        i18n.changeLanguage(newLang);

        setLang(newLang);
    };

    return (
      <LangSelect
        id="lang-select"
        value={lang}
        onChange={handleChange}
        variant="outlined"
        sx={{color: vars.concrete, fontSize: 14}}
      >
        {langs}
      </LangSelect>
    );
}

export default LangPicker;