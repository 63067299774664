import { Stack, SvgIcon } from '@mui/material';
import React from 'react';

import { ReactComponent as Logo } from '../../../assets/logo.svg'
import { vars } from '../../../Theme';

/**
 * Purely cosmetic Component that shows the tp logo and EPLASS CDE wordmark.
 */
const Branding = () =>
{
    return (
      <Stack
        direction="row"
        sx={{ alignItems: "center", color: vars.brandBlue }}
      >
        <SvgIcon
          component={Logo}
          viewBox="0 0 272 28"
          sx={{ width: 253, height: "auto", pr: 1 }}
        />
      </Stack>
    );
};

export default Branding;