import React from "react"
import { Box } from "@mui/material"

/** Purely cosmetic Component for the full screen background image. */
export default () => {
    return (
      <Box
        component="img"
        sx={{
          position: "absolute",
          objectFit: "cover",
          height: "100%",
          width: "100%",
          pointerEvents: "none",
          zIndex: -1
        }}
        src={`${process.env.PUBLIC_URL}/assets/images/background.jpg`}
      />
    );
}