import {ActionReducerMapBuilder, createAsyncThunk, createSlice, Draft, SerializedError} from '@reduxjs/toolkit';
import { IAction } from './store';

/**
 * Initial slice state value.
 */
const initialState: IClientTokenState = {
    loading: false,
    error: null,
    data: null
};

/**
 * Collection of the reducers for this slice.
 */
const reducers = { };

/**
 * Collection of the thunks for this slice, along with their reducer functions.
 */
const thunks = {

    /**
     * Sends the token to the webservice validation endpoint.
     * @param token -> Teh token to validate.
     * @returns IClientToken 
     */
    validateToken: async (token: string): Promise<IClientToken> => 
    {
        try
        {
            if (!token.length) {
                throw new Error("invalid token");
            }

            // Build formData object.
            const formData = new FormData();
            formData.append('token', token);

            const response = await fetch("/api/validate", {
                "method": "POST",
                "body": formData,
            });

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            return {
                isValid: true,
                pwdMinLength: +(await response.text()),
                token: token
            };
        }
        catch(e)
        {
            return Promise.reject(e);
        }
    },

    /**
     * Registers the 'extraReducers' functions for the slice's thunks.
     */
    _reducers: (builder: ActionReducerMapBuilder<IClientTokenState>) =>
    {
        /**
         * Reducers for the 'getProjectFileTypes' thunk.
         */
        builder.addCase(getClientToken.pending, (state: Draft<IClientTokenState>) =>
        {
            state.loading = true;
            state.error = null;
            state.data = null;
        });

        builder.addCase(getClientToken.fulfilled, (state: Draft<IClientTokenState>, action: IAction<IClientToken>) =>
        {
            state.loading = false;
            state.error = null;
            state.data = action.payload;      
        });

        builder.addCase(getClientToken.rejected, (state: Draft<IClientTokenState>, action: IAction<SerializedError>) =>
        {
            state.loading = false;
            state.error = "invalid token";
            state.data = null;      
        });
    }
};

/**
 * Expose thunk functions as exports.
 */
export const getClientToken = createAsyncThunk('validate', thunks.validateToken);


/**
 * Interface that describes the state object for this redux slice.
 */
export interface IClientTokenState
{
    loading: boolean;

    error: string;

    data: IClientToken;
}

/** 
 * Interface for the Client Token information 
 */
export interface IClientToken
{
    token: string;

    isValid: boolean;

    pwdMinLength: number;
}

/**
 * Create the redux slice.
 */
export const clientTokenSlice = createSlice({
    name: 'clientToken',
    extraReducers: thunks._reducers,
    initialState,
    reducers
});

/**
 * Export slice reducer as default.
 */
export default clientTokenSlice.reducer;