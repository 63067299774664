import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import Theme from './Theme';
import registerServiceWorker from './registerServiceWorker';
import { BreakpointProvider } from './context/BreakpointContext';

import ThemeProvider from '@mui/material/styles/ThemeProvider';
import i18n from './i18n';
import store from './redux/store';
import { Provider } from 'react-redux';

const queries = {
    s: '(max-width: 767px)',
    l: '(min-width: 768px)',
};

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <BreakpointProvider queries={queries}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={Theme}>
          <App />
        </ThemeProvider>
      </I18nextProvider>
    </BreakpointProvider>
  </Provider>
);

registerServiceWorker();
