import React, { Dispatch } from "react";
import PasswordIcon from "@mui/icons-material/LockOutlined";

import { useTranslation } from "react-i18next";

import PasswordInfo, {
  IPasswordValidator,
} from "../UI/PasswordInfo/PasswordInfo";
import { Stack, Typography } from "@mui/material";
import TextInput from "../UI/TextInput/TextInput";

import en from "./i18n/PasswordForm_en.json";
import de from "./i18n/PasswordForm_de.json";
import i18n from "../../i18n";
import SubmitButton from "../UI/Button/SubmitButton";
import { IAppReduxStore } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import Status from "../UI/Status/Status";
import PwChangedDialog from "../Dialog/PwChangedDialog";
import { changePw } from "../../redux/slicePwService";

i18n.addResourceBundle("en", "passwordForm", en);
i18n.addResourceBundle("de", "passwordForm", de);

/**
 * Form that allows the user to changes his password.
 */
const PasswordForm = () => {
  // Configure component translation.
  const { t } = useTranslation("passwordForm");

  /** Allows reducers on redux slices to be called. */
  const dispatch: Dispatch<unknown> = useDispatch();

  /** Access relevant state variables from the redux store. */
  const clientTokenData = useSelector(
    (store: IAppReduxStore) => store.clientToken.data
  );
  const pwServiceLoading = useSelector(
    (store: IAppReduxStore) => store.pwService.loading
  );
  const statusType = useSelector((store: IAppReduxStore) => store.status.type);

  const [pw1, setPw1] = React.useState("");
  const [pw2, setPw2] = React.useState("");

  /** Validates the typed in password  */
  const formState = ((): IPasswordValidator => {
    const pwMatch = pw1 === pw2;
    const isPwEmpty = pw1.length <= 0;
    const hasLower = !!pw1.match(/[a-z]/);
    const hasUpper = !!pw1.match(/[A-Z]/);
    const hasNumber = !!pw1.match(/\d/);
    const hasSpecial = !!pw1.match(/[!"#$%&`()*+,\-.\/:;<=>?[\\\]^_{|}]/);
    const isLength = pw1.length >= (clientTokenData?.pwdMinLength || 0);
    const isPwConfirmEmpty = pw2.length <= 0;
    const valid =
      pwMatch && hasLower && hasUpper && hasSpecial && hasNumber && isLength;

    return {
      isPwEmpty,
      hasLower,
      hasUpper,
      hasNumber,
      hasSpecial,
      isLength,
      isPwConfirmEmpty,
      pwMatch,
      valid,
    };
  })();

  /**
   * Sends a request to the backend to change the password
   */
  const sendRequestHandler = () => {
    dispatch(
      changePw({
        token: clientTokenData.token,
        lang: i18n.language,
        pw: pw1,
      })
    );
  };

    if (statusType === "success") {
    //Remove token from url when password updated successfully
    window.history.replaceState(null, "", window.location.origin);
    return <PwChangedDialog />;
  }

  return (
    <Stack spacing={2}>
      <Status />

      <Typography variant="h4" children={t<string>("NewPassword")} />

      <Typography
        variant="body1"
        sx={{ my: 1 }}
        children={t<string>("pleaseNewPw")}
      />

      <TextInput
        key="pw1"
        id="pw1"
        label={t<string>("placeholderPW")}
        value={pw1}
        invalid={
          !formState.isPwEmpty &&
          (!formState.hasUpper ||
            !formState.hasLower ||
            !formState.hasSpecial ||
            !formState.hasNumber)
        }
        onChange={setPw1}
        type="password"
        disabled={pwServiceLoading || !clientTokenData.isValid}
        startIcon={<PasswordIcon />}
        onEnter={() => { formState.valid && sendRequestHandler()}}
      />

      <TextInput
        key="pw2"
        id="pw2"
        label={t<string>("placeholderConfirmPW")}
        value={pw2}
        invalid={!formState.isPwConfirmEmpty && !formState.pwMatch}
        onChange={setPw2}
        type="password"
        disabled={pwServiceLoading || !clientTokenData.isValid}
        startIcon={<PasswordIcon />}
        onEnter={() => { formState.valid && sendRequestHandler()}}
      />

      <PasswordInfo passwordValidator={formState} />

      <SubmitButton
        disabled={
          !formState.valid || pwServiceLoading || !clientTokenData.isValid
        }
        onClick={sendRequestHandler}
        label={t<string>("save")}
      />
    </Stack>
  );
};

export default PasswordForm;
