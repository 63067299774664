import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
} from "@mui/material";
import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

/** Formular Input Field Component. */
export default (props: ITextInputProps) => {
  const [showPw, setShowPw] = useState(false);
  const [inputHasFocus, setInputHasFocus] = useState(false);

  /** Deconstruct props. */
  const {
    id,
    value,
    onChange,
    type,
    label,
    invalid,
    disabled,
    startIcon,
    onEnter,
  } = props;

  const inputProps: OutlinedInputProps = {
    id,
    value,
    type,
    disabled,
    onChange: (e) => onChange(e.target.value),
    onFocusCapture: () => setInputHasFocus(true),
    onBlur: () => setInputHasFocus(false),
    error: !!invalid,
  };

  /** Handles the visible state of the password  */
  const handlePwBtnClick = (e: React.MouseEvent) => {
    e.preventDefault();

    setShowPw(!showPw);
  };

  const showLabel = inputHasFocus || !!value;

  if (showLabel) {
    inputProps.label = label;
  } else {
    inputProps.placeholder = label;
  }

  if (type === "password" && showPw) {
    inputProps.type = "text";
  }

  if (startIcon) {
    inputProps.startAdornment = (
      <InputAdornment position="start">{startIcon}</InputAdornment>
    );
  }

  if (onEnter) {
    inputProps.onKeyDown = (e) => {
      e.key.toLocaleLowerCase() === "enter" && onEnter();
    };
  }

  if (type === "password" && inputHasFocus) {
    inputProps.endAdornment = (
      <InputAdornment position="end">
        <IconButton onMouseDown={handlePwBtnClick} tabIndex={-1}>
          {showPw ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  }

  return (
    <FormControl variant="outlined" sx={{ my: 1 }} fullWidth error={!!invalid}>
      {showLabel && <InputLabel htmlFor={id}>{label}</InputLabel>}
      <OutlinedInput {...inputProps} />
    </FormControl>
  );
};

/** Inteface for the ITextInput props */
export interface ITextInputProps {
  id: string;
  label: string;
  value: string;
  onChange: (v: string) => void;
  type: "text" | "password";
  invalid?: boolean;
  disabled?: boolean;
  startIcon?: JSX.Element;
  onEnter?: () => void;
}
