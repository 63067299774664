import React, { createContext, useContext, useEffect, useState } from 'react';

interface IDictionary<T>
{
    [id: string]: T;
}

const defaultValue: IDictionary<boolean> = {};

const BreakpointContext: React.Context<IDictionary<boolean>> = createContext(defaultValue);

const BreakpointProvider = ({ children, queries }: any) =>
{
    const [queryMatch, setQueryMatch]: [IDictionary<boolean>, (value: IDictionary<boolean>) => void] = useState({});

    useEffect(() =>
    {
        const mediaQueryLists: IDictionary<MediaQueryList> = {};
        const keys: string[] = Object.keys(queries);

        let isAttached: boolean = false;

        const eventAdmin = (add: boolean): void =>
        {
            keys.forEach((media: string): void =>
            {
                if (typeof queries[media] === 'string')
                {
                    mediaQueryLists[media][add ? 'addListener' : 'removeListener'](handleQueryListener);
                }
            });
        };

        const handleQueryListener = (): void =>
        {
            const updatedMatches: IDictionary<boolean> = keys.reduce((acc: IDictionary<boolean>, media: string) =>
            {
                acc[media] = !!mediaQueryLists[media]?.matches;

                return acc;
            }, {});

            setQueryMatch(updatedMatches);
        }

        if (window?.matchMedia)
        {
            const matches: IDictionary<boolean> = {};

            keys.forEach((media: string): void =>
            {
                if (typeof queries[media] !== 'string')
                {
                    matches[media] = false

                    return;
                }

                mediaQueryLists[media] = window.matchMedia(queries[media]);
                matches[media] = mediaQueryLists[media].matches
            });

            setQueryMatch(matches);

            isAttached = true;

            eventAdmin(true);
        }

        return () =>
        {
            if (isAttached)
            {
                eventAdmin(false);
            }
        };
    }, [queries]);

    return <BreakpointContext.Provider value={queryMatch}>{children}</BreakpointContext.Provider>;
}

const useBreakpoint = (): IDictionary<boolean> =>
{
    const context: IDictionary<boolean> = useContext(BreakpointContext);

    if (context === defaultValue)
    {
        throw new Error('useBreakpoint must be used within BreakpointProvider');
    }

    return context;
}

export { useBreakpoint, BreakpointProvider };