import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

/** Supported Application Languages. */
const langs = ["en", "de"];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    partialBundledLanguages: true,
    supportedLngs: langs,
    fallbackLng: langs,
    resources: {},
    ns: [],
  });

  export default i18n;
