import React from "react";

import CheckCircle from "@mui/icons-material/CheckCircle";
import Cancel from "@mui/icons-material/Cancel";

import { useTranslation } from "react-i18next";
import { Stack, SxProps, Typography } from "@mui/material";
import { vars } from "../../../Theme";

import en from "./i18n/PasswordInfo_en.json";
import de from "./i18n/PasswordInfo_de.json";
import i18n from "../../../i18n";
import { IAppReduxStore } from "../../../redux/store";
import { useSelector } from "react-redux";

i18n.addResourceBundle("en", "passwordInfo", en);
i18n.addResourceBundle("de", "passwordInfo", de);

/** Validates if a condition is set, true or false. */
const checkState = (v: IPasswordValidator, key: keyof IPasswordValidator) => {
  const condition = key === "pwMatch" ? v.isPwConfirmEmpty : v.isPwEmpty;

  return [condition || v[key], condition];
};

/** Returns styling for the password condition icon. */
const getIconSx = (color: string): SxProps => ({
  height: 18,
  color,
});

/** Returns a single password condition Icon component. */
const GetPwConditionIcon = (
  validator: IPasswordValidator,
  key: keyof IPasswordValidator
) => {
  const [passed, clean] = checkState(validator, key);

  if (!passed) {
    return <Cancel sx={getIconSx(vars.errorRed)} />;
  }

  return <CheckCircle sx={getIconSx(clean ? vars.midGray : vars.brandGreen)} />;
};

/** Returns a single password condition component. */
const PwCondition = (
  validator: IPasswordValidator,
  key: keyof IPasswordValidator,
  text: string
) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    {GetPwConditionIcon(validator, key)}
    <Typography
      variant="body1"
      color={vars.concrete}
      fontSize={13}
      children={text}
    />
  </Stack>
);

/** Component that shows the password policy information. */
const PasswordInfo = (props: IPasswordInfoProps) => {
  // Configure component translation.
  const { t } = useTranslation("passwordInfo");

  const clientTokenData = useSelector(
    (store: IAppReduxStore) => store.clientToken.data
  );

  const p = props?.passwordValidator;

  if (!p) {
    return <></>;
  }

  return (
    <Stack sx={{ my: 2 }} spacing={0.25}>
      {PwCondition(
        p,
        "isLength",
        `${clientTokenData?.pwdMinLength || "-"} ${t("characters")}`
      )}
      {PwCondition(p, "hasUpper", t("oneUpper"))}
      {PwCondition(p, "hasLower", t("oneLower"))}
      {PwCondition(p, "hasSpecial", t("oneSpecial"))}
      {PwCondition(p, "hasNumber", t("oneNumber"))}
      {PwCondition(p, "pwMatch", t("pwConfirmed"))}
    </Stack>
  );
};

/** Props interface for the password info component. */
export interface IPasswordInfoProps {
  passwordValidator?: IPasswordValidator;
}

/** Interface for the password condition validatior. */
export interface IPasswordValidator {
  isPwEmpty: boolean;
  isPwConfirmEmpty: boolean;
  isLength: boolean;
  hasUpper: boolean;
  hasLower: boolean;
  hasSpecial: boolean;
  hasNumber: boolean;
  pwMatch: boolean;
  valid: boolean;
}

export default PasswordInfo;
