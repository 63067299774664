import { ActionReducerMapBuilder, createSlice, Draft, SerializedError } from "@reduxjs/toolkit";
import { IAction } from "./store";
import { registerUser, changePw, validateCode } from "./slicePwService";
import { getClientToken } from "./sliceClientToken";

/**
 * Initial slice state value.
 */
const initialState: IStatusState = {
  message: null,
  type: null,
};

/**
 * Collection of the reducers for this slice.
 */
const reducers = {
  setMessage(state: IStatusState, action: IAction<string>) {
    state.message = action.payload;
  },

  setType(state: IStatusState, action: IAction<StatusType>) {
    state.type = action.payload;
  },

  updateStatus(state: IStatusState, action: IAction<IStatusState>) {
    state.message = action.payload.message;
    state.type = action.payload.type;
  },
};

/**
 * Collection of the thunks for this slice, along with their reducer functions.
 */
const thunks = {

    /**
     * Registers the 'extraReducers' functions for the slice's thunks.
     */
    _reducers: (builder: ActionReducerMapBuilder<IStatusState>) =>
    {
        /**
         * Reducers for the 'registerUser' thunk from slicePwService.
         */
        builder.addCase(registerUser.pending, (state: Draft<IStatusState>) =>
        {
            state.message = null;
            state.type = null;
        });

        builder.addCase(registerUser.fulfilled, (state: Draft<IStatusState>) =>
        {
            state.message = null;
            state.type = 'success';
        });

        builder.addCase(registerUser.rejected, (state: Draft<IStatusState>, action: IAction<SerializedError>) =>
        {
            state.message = action.error?.message || 'invalidCredentials';
            state.type = 'error';  
        });

         /**
         * Reducers for the 'changePw' thunk from slicePwService.
         */
         builder.addCase(changePw.pending, (state: Draft<IStatusState>) =>
         {
             state.message = null;
             state.type = null;  
         });

         builder.addCase(changePw.fulfilled, (state: Draft<IStatusState>, action: IAction<string>) =>
         {
            state.message = null;
            state.type = 'success';
         });
 
         builder.addCase(changePw.rejected, (state: Draft<IStatusState>) =>
         {
             state.message = 'pwIssue';
             state.type = 'error';  
         });

         /**
         * Reducers for the 'getClientToken' thunk from sliceClientToken.
         */
         builder.addCase(getClientToken.pending, (state: Draft<IStatusState>) =>
         {
             state.message = null;
             state.type = null;  
         });

         /**
         * Reducers for the 'getClientToken' thunk from sliceClientToken.
         */
         builder.addCase(getClientToken.rejected, (state: Draft<IStatusState>) =>
         {
             state.message = 'tokenInvalid';
             state.type = 'error';  
         });

         builder.addCase(validateCode.pending, (state: Draft<IStatusState>) =>
          {
            state.message = null;
            state.type = null;  
          });

          builder.addCase(validateCode.fulfilled, (state: Draft<IStatusState>) =>
          {
              state.message = null;
              state.type = 'successCode';
          });
  
          builder.addCase(validateCode.rejected, (state: Draft<IStatusState>) =>
          {
              state.message = 'codeInvalid';
              state.type = 'errorCode';  
          });
    }

}

/** 
 * Type of the statustype 
 */
export type StatusType = "success" | "error" | "successCode" | "errorCode";


/**
 * Interface that describes the state object for this redux slice.
 */
export interface IStatusState {
  message: string;

  type?: StatusType;
}

/**
 * Create the redux slice.
 */
export const statusSlice = createSlice({
  name: "Status",
  extraReducers: thunks._reducers,
  initialState,
  reducers,
});

/**
 * Export functions from slice actions.
 */
export const { setMessage, setType, updateStatus } = statusSlice.actions;

/**
 * Export slice reducer as default.
 */
export default statusSlice.reducer;
