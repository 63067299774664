import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  Draft,
  SerializedError,
} from "@reduxjs/toolkit";
import { IAction } from "./store";

/**
 * Initial slice state value.
 */
const initialState: IPwServiceState = {
  loading: false,
  error: null,
  data: null,
};

/** Sets the loading state. */
const setStateLoading = (state: Draft<IPwServiceState>) => {
  state.loading = true;
  state.error = null;
  state.data = null;
};

/** Sets the success state & data. */
const setStateFullfilled = (
  state: Draft<IPwServiceState>,
  action: IAction<string>
) => {
  state.loading = false;
  state.error = null;
  state.data = action.payload;
};

/** Sets the error state & data. */
const setValidateStateError = (
  state: Draft<IPwServiceState>,
  action: IAction<SerializedError>
) => {
  state.loading = false;
  state.error = action.payload;
  state.data = 'expired';
};

/** Sets the loading state. */
const setValidateStateLoading = (state: Draft<IPwServiceState>) => {
  state.loading = true;
  state.error = null;
  state.data = 'expired';
};

/** Sets the error state & data. */
const setStateError = (
  state: Draft<IPwServiceState>,
  action: IAction<SerializedError>
) => {
  state.loading = false;
  state.error = action.payload;
  state.data = null;
};

/**
 * Collection of the reducers for this slice.
 */
const reducers = {};

/**
 * Collection of the thunks for this slice, along with their reducer functions.
 */
const thunks = {

    registerUser: async (config: {username: string, lang: string}): Promise<string> => 
    {
    try {
      const { username, lang } = config;

      // Build formData object.
      const formData = new FormData();
      formData.append("username", username);
      formData.append("langKey", lang);

      const response = await fetch("/api/register", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      return await response.text();
    } catch (e) {
      return Promise.reject(e);
    }
  },

    changePw: async (config: {pw: string, token: string, lang: string}): Promise<string> => 
    {
    try {
      const { pw, token, lang } = config;

      if (!pw.length || !token.length || !lang.length) {
        throw new Error();
      }

      // Build formData object.
      const formData = new FormData();
      formData.append("token", token);
      formData.append("pw", pw);
      formData.append("langKey", lang);

      const response = await fetch("/api/changepassword", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return await response.text();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  validateCode: async (config: {
    code: string;
    username: string;
    lang: string;
  }): Promise<string> => {
    try {
      const { code, username, lang } = config;

      if (!code.length) {
        throw new Error("invalid token");
      }

      // Build formData object.
      const formData = new FormData();
      formData.append("username", username);
      formData.append("eplasscode", code);
      formData.append("langKey", lang);

      const response = await fetch("api/date/reset", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      return await response.text();
    } catch (e) {
      return Promise.reject(e);
    }
  },

  /**
   * Registers the 'extraReducers' functions for the slice's thunks.
   */
  _reducers: (builder: ActionReducerMapBuilder<IPwServiceState>) => {
    /**
     * Reducers for the 'registerUser' thunk.
     */
    builder.addCase(registerUser.pending, setStateLoading);

    builder.addCase(registerUser.fulfilled, setStateFullfilled);

    builder.addCase(registerUser.rejected, setStateError);

    /**
     * Reducers for the 'changePw' thunk.
     */
    builder.addCase(changePw.pending, setStateLoading);

    builder.addCase(changePw.fulfilled, setStateFullfilled);

    builder.addCase(changePw.rejected, setStateError);

    /**
     * Reducers for the 'validateCode' thunk.
     */
    builder.addCase(validateCode.pending, setValidateStateLoading);

    builder.addCase(validateCode.fulfilled, setStateFullfilled);

    builder.addCase(validateCode.rejected, setValidateStateError);
  },
};

/**
 * Expose thunk functions as exports.
 */
export const registerUser = createAsyncThunk("register", thunks.registerUser);
export const changePw = createAsyncThunk("change", thunks.changePw);
export const validateCode = createAsyncThunk(
  "validateCode",
  thunks.validateCode
);

/**
 * Interface that describes the state object for this redux slice.
 */
export interface IPwServiceState {
  loading: boolean;

  data: string;

  error: SerializedError;
}

/**
 * Create the redux slice.
 */
export const pwServiceSlice = createSlice({
  name: "pwService",
  extraReducers: thunks._reducers,
  initialState,
  reducers,
});

/**
 * Export slice reducer as default.
 */
export default pwServiceSlice.reducer;