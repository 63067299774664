import { Box, keyframes, Stack, SxProps, Typography } from '@mui/material';
import React from 'react';
import { vars } from '../../../Theme';

/**
 * Loading component animation frames.
 */
const movement = keyframes`
    0% {
        transform: translate3d(0, -25px, 0);
        z-index: 0;
    }
    50% {
        transform: translate3d(0, 25px, 0);
        z-index: 10;
    }
    100% {
        transform: translate3d(0, -25px, 0);
        z-index: -5;
    }
`;

/** Styles for making an element flexbox-compatible. */
export const flexBase: SxProps = {
    display: 'flex',
    alignItems: 'center',
};

/** Styles for making an element take up all available size from its parent. */
export const fullSize: SxProps = {
    height: '100%',
    width: '100%',
};

/**
 * Gets the styles for a given dot in the loader.
 * @param delay - Animation start delay.
 * @param i - Dot index.
 */
const dotSx = (delay: number, i: number): SxProps =>
({
    transition: 'all 0.5s ease',
    transform: 'translate3d(0, -25px, 0) scale(1)',
    animation: `${movement} 2s infinite ease`,
    animationDelay: `-${delay.toFixed(1)}s`,
    bgcolor: !(i % 2) ? vars.brandBlue : vars.brandGreen,
    top: 'calc(50% - 6px)',
    borderRadius: '100%',
    position: 'absolute',
    height: 12,
    width: 12,
    left: (Math.floor(i / 2) + 1) * 25,
});

/**
 * Loading screen component that looks like a DNA helix.
 */
export default (props: { message?: string; }) => {
    /** Create 26 dots for the loading helix. */
    const dots = new Array(26)
        .fill(null)
        .map((_, i) => {
            const delay = i % 2 === 0
                ? 0.1 * (i + 1.1)
                : ((Math.floor(i / 2) * 0.2) + 1);

            return <Box key={`loading_${i}`} sx={dotSx(delay, i,)} />
        });

    return (
        <Box sx={{ ...flexBase, ...fullSize, position: 'relative', justifyContent: 'center' } as SxProps}>
            <Stack sx={{ width: 360 }} alignItems='center'>
                <Box sx={{ height: 100, width: '100%', position: 'relative' }}>
                    {dots}
                </Box>

                {props.message && (
                    <Typography variant='h5'>
                        {props.message}
                    </Typography>
                )}
            </Stack>
        </Box>
    );
};
