import React, { useState, Dispatch, useEffect } from "react";
import PersonIcon from "@mui/icons-material/PersonOutlined";
import { useTranslation } from "react-i18next";

import TextInput from "../UI/TextInput/TextInput";
import { Stack, Typography, Box } from "@mui/material";

import SubmitButton from "../UI/Button/SubmitButton";
import { useDispatch, useSelector } from "react-redux";
import { IAppReduxStore } from "../../redux/store";
import Status from "../UI/Status/Status";
import { registerUser, validateCode } from "../../redux/slicePwService";
import SuccessDialog from "../Dialog/SuccessDialog";
import SuccessForwardDialog from "../Dialog/SuccessForwardDialog";
import { updateStatus } from "../../redux/sliceStatus";

import i18n from "../../i18n";
import en from "./i18n/RegisterForm_en.json";
import de from "./i18n/RegisterForm_de.json";

i18n.addResourceBundle("en", "registerForm", en);
i18n.addResourceBundle("de", "registerForm", de);

/**
 * Form that allows the user register for a password change process.
 */
const RegisterForm = () => {
    // Configure component translation.
    const { t } = useTranslation("registerForm");

    // State fot getting the username.
    const [username, setUsername] = useState("");

    // State fot getting the username.
    const [code, setCode] = useState("");

    /** Access relevant state variables from the redux store. */
    const statusType = useSelector((store: IAppReduxStore) => store.status.type);
    const pwServiceLoading = useSelector(
        (store: IAppReduxStore) => store.pwService.loading
    );

    const pwServiceData = useSelector(
        (store: IAppReduxStore) => store.pwService.data
    );

    /** Allows reducers on redux slices to be called. */
    const dispatch: Dispatch<unknown> = useDispatch();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const message = urlParams.get('message');

        if (message) {
            dispatch(updateStatus({ message: message, type: "error" }));
        }
    }, [])

    /**
     * Sends a request to the backend to register user password change token.
     */
    const sendRequestHandler = () => {
        dispatch(
            registerUser({
                username: username,
                lang: i18n.language,
            })
        );
    };

    const sendCodeRequestHandler = () => {
        dispatch(validateCode({ code: code.trim(), username: username, lang: i18n.language, }));
    };

    const isForwardUrl = !!pwServiceData?.match(/https:/);

    if (isForwardUrl) {

        window.open(pwServiceData, "_self");

        return <SuccessForwardDialog link={pwServiceData} />
    }

    const isExpired = !!pwServiceData?.match(/expired/);

    if (statusType === "success" && !isExpired) {
        return <SuccessDialog />;
    }

    return (
        <Stack spacing={2}>
            <Status />

            <Typography variant="h4" children={isExpired ? t<string>("headerExpired") : t<string>("changeLogin")} />

            <Typography
                variant="body1"
                sx={{ my: 1 }}
                children={isExpired ? t<string>("textExpired") : t<string>("pleaseUsername")}
            />

            <TextInput
                id="username"
                label={t<string>("placeholderUN")}
                value={username}
                onChange={setUsername}
                type="text"
                invalid={statusType === "error"}
                startIcon={<PersonIcon />}
                disabled={pwServiceLoading || isExpired}
                onEnter={() => {
                    username && sendRequestHandler();
                }}
            />

            {isExpired ? (
                <TextInput
                    id="code"
                    label={t<string>("placeholderExpired")}
                    value={code}
                    onChange={setCode}
                    type="text"
                    invalid={statusType === "errorCode"}
                    startIcon={<PersonIcon />}
                    disabled={pwServiceLoading}
                    onEnter={() => {
                        code && sendCodeRequestHandler();
                    }}
                />
            ) : (
                <></>
            )}

            <Box sx={{ height: 125 }}>
                <Typography
                    variant="body1"
                    sx={{ my: 1 }}
                    children={t<string>("noteCheckSpam")}
                />
            </Box>

            {isExpired ? (
                <SubmitButton
                    disabled={!code || pwServiceLoading}
                    onClick={sendCodeRequestHandler}
                    label={t<string>("approveExpired")}
                />
            ) : (
                <SubmitButton
                    disabled={!username || pwServiceLoading}
                    onClick={sendRequestHandler}
                    label={t<string>("sendEmail")}
                />
            )}
        </Stack>
    );
};

export default RegisterForm;
