import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Link, Stack, Typography } from '@mui/material';
import { montserratSemiBold, vars } from '../../Theme';
import Status from '../UI/Status/Status';

import i18n from '../../i18n';
import en from './i18n/SuccessDialog_en.json';
import de from './i18n/SuccessDialog_de.json';

i18n.addResourceBundle('en', 'successDialog', en);
i18n.addResourceBundle('de', 'successDialog', de);

/**
 * Dialog for showing a email sent success message to the user.
 */
const SuccessForwardDialog = ({ link }: { link: string }) => {

  // Configure component translation.
  const { t } = useTranslation("successDialog");

  return (
    <Stack sx={{ mb: 4, textAlign: "left", maxWidth: 364 }} spacing={2}>
      <Status />

      <Typography
        variant="h4"
        children={t<string>("forwardtitle")}
        sx={{ my: 1 }}
      />

      <Typography
        variant="body1"
        children={t<string>("forwardmessage")}
        sx={{ my: 1 }}
      />

    <Typography
        variant="body1"
        children={t<string>("forwardmessage2")}
        sx={{ my: 1 }}
      />

      <Link href={link}>{t<string>("forwardLink")}</Link>

      <Box sx={{ height: 125 }} />

      <Stack
        direction="row"
        sx={{ width: "100%", m: 8 }}
      >
        <Box
          onClick={() => window.location.reload()}
          sx={{
            cursor: "pointer",
            color: vars.brandGreen,
            ...montserratSemiBold,
            fontWeight: "bold",
          }}
        >
          {t("goback")}
        </Box>

        <Box flexGrow={4} />
      </Stack>
    </Stack>
  );
};

export default SuccessForwardDialog;
