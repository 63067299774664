import React, { useEffect, Dispatch } from "react";

import "./Styles.scss";
import RegisterForm from "./components/RegisterForm/RegisterForm";
import PasswordForm from "./components/PasswordForm/PasswordForm";
import { useDispatch, useSelector } from "react-redux";
import { getClientToken } from "./redux/sliceClientToken";
import { IAppReduxStore } from "./redux/store";
import Loading from "./components/UI/Loading/Loading";
import Formular from "./components/Formular/Formular";

const App = () => {
  /** Allows reducers on redux slices to be called. */
  const dispatch: Dispatch<unknown> = useDispatch();

  /** Access relevant state variables from the redux store. */
  const clientTokenData = useSelector((store: IAppReduxStore) => store.clientToken.data);
  const clientTokenLoading = useSelector((store: IAppReduxStore) => store.clientToken.loading);

  /** Validates to token when the application loads. */
  useEffect(() => {
    const token: string = window.location.pathname.substring(1) ?? "";

    if (!token) {
      return;
    }

    dispatch(getClientToken(token));
  }, []);

  if (clientTokenLoading) {
    return <Loading message="Loading" />;
  }

  return (
      <Formular>
          {!clientTokenData ? <RegisterForm /> : <PasswordForm />}
    </Formular>
  );
};

export default App;
