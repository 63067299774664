import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Stack } from "@mui/material"
import LangPicker from "../LangPicker/LangPicker"
import { vars } from '../../../Theme';
import i18n from '../../../i18n';
import en from './i18n/Footer_en.json';
import de from './i18n/Footer_de.json';

i18n.addResourceBundle('en', 'langPicker', en);
i18n.addResourceBundle('de', 'langPicker', de);

export default () => {
  
  // Configure translation.
  const { t } = useTranslation("langPicker");

  /**
   * Redirects the User to the language specific support page.
   */
  const goToSupport = () => {
    window.location.href =
      i18n.language === "de"
        ? "https://www.eplass.de/support/eplass/support.html"
        : "https://www.eplass.com/support-en/eplass-support-664.html";
  };

  /** 
   * The Formular Footer Component.
   */
  return (
    <Stack spacing={1}>
      <Divider flexItem />
      <Stack
        direction="row"
        sx={{ width: "100%", px: 1, color: vars.concrete, fontSize: 14 }}
      >
        <Box
          onClick={goToSupport}
          sx={{
            cursor: "pointer",
          }}
        >
          {t("help")}
        </Box>
        <Box flexGrow={4} />
        <LangPicker />
      </Stack>
    </Stack>
  );
};