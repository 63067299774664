import React from 'react';
import { useTranslation } from 'react-i18next';

import en from './i18n/SuccessDialog_en.json';
import de from './i18n/SuccessDialog_de.json';
import i18n from '../../i18n';
import { Box, Stack, Typography } from '@mui/material';
import Status from '../UI/Status/Status';

i18n.addResourceBundle('en', 'successDialog', en);
i18n.addResourceBundle('de', 'successDialog', de);

/**
 * Dialog for showing a password changed success message to the user.
 */
const PwChangedDialog = () => {
    // Configure component translation.
    const { t } = useTranslation('successDialog');

    return (
      <Stack
        sx={{ mb: 4, textAlign: "left", maxWidth: 364 }}
        spacing={2}
      >
        <Status />

        <Typography
          variant="h4"
          children={t<string>('passwordchanged')}
          sx={{ my: 1 }}
        />

        <Typography
          variant="body1"
          children={t<string>("changedmessage")}
          sx={{ my: 1 }}
        />

        <Box sx={{ height: 125 }} />
      </Stack>
    );
};

export default PwChangedDialog;
