import { configureStore, SerializedError } from '@reduxjs/toolkit';
import clientTokenReducer, { IClientTokenState } from './sliceClientToken';
import statusReducer, { IStatusState } from './sliceStatus';
import pwServiceReducer, { IPwServiceState } from './slicePwService';


/**
 * Interface that describes an action that is run against a slice's state object.
 */
export interface IAction<T>
{
    /** Action type. */
    type: string;

    /** Action payload object. */
    payload: T;

    /** (Optional) Action error object. */
    error?: SerializedError;
}

/**
 * Interface that describes the structure of the Application Redux store.
 */
export interface IAppReduxStore {
  clientToken: IClientTokenState;
  status: IStatusState;
  pwService: IPwServiceState;
}

/**
 * Application Redux store.
 */
export default configureStore({
    reducer: {
        clientToken: clientTokenReducer,
        status: statusReducer,
        pwService: pwServiceReducer,
    }
});