import { Stack } from '@mui/material';
import React from 'react';
import { useBreakpoint } from '../../context/BreakpointContext';
import { vars } from '../../Theme';
import BgImage from '../UI/BackgroundImage/BgImage';
import Branding from '../UI/Branding/Branding';
import Footer from '../UI/Footer/Footer';

/** Formular wrapper component. */
export default (props) => {
  // Determine screen size.
  const breakpoints: any = useBreakpoint();

  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      {breakpoints.l && <BgImage />}
      <Stack
        spacing={2}
        sx={{
          backgroundColor: vars.white,
          py: 4,
          px: 8,
          width: 400
        }}
      >
        <Branding />

        {props.children}

        <Footer />
      </Stack>
    </Stack>
  );
};